<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('users.user_details')}}</h1>
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            <mdb-row v-if="userdetails">
              <mdb-col md="6">
                <mdb-card>
                  <mdb-card-header color="blue darken-3">Informations</mdb-card-header>
                  <mdb-card-body>
                    <mdb-input :label="$t('users.name')" type="text" v-model="userdetails.name" />
                    <mdb-input :label="$t('users.email')" type="text" v-model="userdetails.email" />
                    <mdb-input :label="$t('users.new_password')" type="password" v-model="newpassword" />
                    <mdb-select
                      color="primary"
                      v-model="accesslevels"
                      :label="$t('users.accesslevel')"
                      v-on:getValue="getSelectedValue"
                      :visibleOptions=10
                    />
                    <mdb-btn color="elegant" type="button" class="btn-block z-depth-2" v-on:click="saveuser()">{{$t('users.edit')}}</mdb-btn>
                  </mdb-card-body>
                </mdb-card>
                <mdb-card class="mt-3">
                  <mdb-card-header color="green darken-3">Notifications</mdb-card-header>
                  <mdb-card-body v-if="userdetails.accesslevel === 'FULLACCESS'">
                    <mdb-alert color="info">{{$t('users.notificationstypes')}}</mdb-alert>
                    <div v-for="(section,index) in notificationspreferences" v-bind:key="index" class="pb-2">
                      <h4>{{section.category}}</h4>
                      <mdb-switch v-for="(key,keyindex) in section.types" :id="`${index}_${key.value}`" v-bind:key="keyindex" v-model="notificationspreferences[index].types[keyindex].selected" :onLabel="key.text" offLabel="" />
                      <hr />
                    </div>
                    <mdb-btn color="elegant" type="button" class="btn-block z-depth-2" v-on:click="updatenotifications()">{{$t('users.edit')}}</mdb-btn>
                  </mdb-card-body>
                  <mdb-card-body v-else>
                    <mdb-alert color="info">Only available for FULLACCESS users</mdb-alert>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="6">
                <mdb-card>
                  <mdb-card-header color="info-color darken-3">{{$t('users.last_logins')}}</mdb-card-header>
                  <mdb-card-body>
                    <mdb-tbl btn striped v-if="accesslogs.length">
                      <mdb-tbl-head>
                        <tr>
                          <th>Date</th>
                          <th>IP</th>
                          <th>{{$t('users.origin')}}</th>
                        </tr>
                      </mdb-tbl-head>
                      <mdb-tbl-body>
                        <tr v-for="log in accesslogs" v-bind:key="log.id">
                          <td><strong>{{lastConnectionFormat(log.created_at)}}</strong></td>
                          <td>{{log.ip}}</td>
                          <td>{{log.city}}<br />{{log.region}}, {{log.country}}</td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                    <h3 v-else>{{$t('users.no_activity')}}</h3>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbAlert,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbRow,
  mdbCol,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbSelect,
  mdbSwitch,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'userDetails',
  data() {
    return {
      error: null,
      success: null,
      userdetails: null,
      newpassword: '',
      confirm: false,
      accesslogs: [],
      accesslevels: [
        { text: 'FULLACCESS', value: 'FULLACCESS', selected: false },
        { text: 'HOLASUN', value: 'HOLASUN', selected: false },
        { text: 'AGENT', value: 'AGENT', selected: false },
        { text: 'GATEWAYAGENT', value: 'GATEWAYAGENT', selected: false },
      ],
      notificationspreferences: [
        {
          category: 'Holasun Manifest',
          types: [
            { text: 'Pax with missing birthdate', value: 'PAXNOBIRTHDATE', selected: false },
            { text: 'Invalid filename for manifest', value: 'HOLASUNINVALIDFILENAME', selected: false },
          ],
        },
        {
          category: 'Flight',
          types: [
              { text: 'Too many pax for a flight (OVERBOOKING)', value: 'OVERBOOKING', selected: false },
            { text: 'Can\'t handle a crew change automatically [Not active right now]', value: 'CREWCHANGEERROR', selected: false },
          ],
        },
        {
          category: 'Aircraft',
          types: [
            { text: 'Aircraft Take-off', value: 'AIRCRAFTTAKEOFF', selected: false },
            { text: 'Aircraft Landing', value: 'AIRCRAFTLANDING', selected: false },
          ],
        },
      ],
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbAlert,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbRow,
    mdbCol,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbSelect,
    mdbSwitch,
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/user/details', { id: Number(this.$route.params.id) }).then((response) => {
        if (response.status === 'OK') {
          self.userdetails = response.data;
          self.accesslogs = self.userdetails.accesslogs;
          Object.keys(self.accesslevels).forEach((key) => {
            self.accesslevels[key].selected = self.userdetails.accesslevel === self.accesslevels[key].value;
          });
          for (let i = 0; i < this.notificationspreferences.length; i += 1) {
            for (let j = 0; j < this.notificationspreferences[i].types.length; j += 1) {
              this.notificationspreferences[i].types[j].selected = self.userdetails.receivenotifications.filter((elm) => elm.notificationkey === this.notificationspreferences[i].types[j].value).length > 0;
            }
          }
        } else {
          self.error = response.msg;
        }
      });
    },
    lastConnectionFormat(elm) {
      const ladate = `${this.getDate(elm)} ${this.getTime(elm)}`;
      return ladate;
    },
    getDate(timestamp) {
      const thedate = new Date(timestamp);
      thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getTime(timestamp) {
      const thedate = new Date(timestamp);
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleTimeString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    saveuser() {
      const self = this;
      const api = new Apicall();
      this.error = null;
      this.success = null;
      api.call('POST', 'api/user/edit', { userdetails: self.userdetails, newpassword: self.newpassword }).then((response) => {
        if (response.status === 'OK') {
          self.success = response.msg;
        } else {
          self.error = response.msg;
        }
      });
    },
    getSelectedValue(value) {
      this.userdetails.accesslevel = value;
    },
    updatenotifications() {
      const self = this;
      const api = new Apicall();
      this.error = null;
      this.success = null;
      api.call('POST', 'api/user/updatenotificationspreferences', { userid: this.userdetails.id, payload: this.notificationspreferences }).then((response) => {
        if (response.status === 'OK') {
          self.success = response.msg;
        } else {
          self.error = response.msg;
        }
      });
    },
  },
};
</script>
